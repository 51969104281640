/*#lang-switcher{*/

/*z-index:1000;*/
/*    position: fixed;*/
/*    overflow: none;*/


/*}*/
/*@media (min-width:600px){.col-sm-1,.col-sm-10,.col-sm-11,.col-sm-12,.col-sm-2,.col-sm-3,.col-sm-4,.col-sm-5,.col-sm-6,.col-sm-7,.col-sm-8,.col-sm-9{float:left}.col-sm-12{width:100%}.col-sm-11{width:91.66666667%}.col-sm-10{width:83.33333333%}.col-sm-9{width:75%}.col-sm-8{width:66.66666667%}.col-sm-7{width:58.33333333%}.col-sm-6{width:50%}.col-sm-5{width:41.66666667%}.col-sm-4{width:33.33333333%}.col-sm-3{width:25%}.col-sm-2{width:16.66666667%}.col-sm-1{width:8.33333333%}.col-sm-pull-12{right:100%}.col-sm-pull-11{right:91.66666667%}.col-sm-pull-10{right:83.33333333%}.col-sm-pull-9{right:75%}.col-sm-pull-8{right:66.66666667%}.col-sm-pull-7{right:58.33333333%}.col-sm-pull-6{right:50%}.col-sm-pull-5{right:41.66666667%}.col-sm-pull-4{right:33.33333333%}.col-sm-pull-3{right:25%}.col-sm-pull-2{right:16.66666667%}.col-sm-pull-1{right:8.33333333%}.col-sm-pull-0{right:auto}.col-sm-push-12{left:100%}.col-sm-push-11{left:91.66666667%}.col-sm-push-10{left:83.33333333%}.col-sm-push-9{left:75%}.col-sm-push-8{left:66.66666667%}.col-sm-push-7{left:58.33333333%}.col-sm-push-6{left:50%}.col-sm-push-5{left:41.66666667%}.col-sm-push-4{left:33.33333333%}.col-sm-push-3{left:25%}.col-sm-push-2{left:16.66666667%}.col-sm-push-1{left:8.33333333%}.col-sm-push-0{left:auto}.col-sm-offset-12{margin-left:100%}.col-sm-offset-11{margin-left:91.66666667%}.col-sm-offset-10{margin-left:83.33333333%}.col-sm-offset-9{margin-left:75%}.col-sm-offset-8{margin-left:66.66666667%}.col-sm-offset-7{margin-left:58.33333333%}.col-sm-offset-6{margin-left:50%}.col-sm-offset-5{margin-left:41.66666667%}.col-sm-offset-4{margin-left:33.33333333%}.col-sm-offset-3{margin-left:25%}.col-sm-offset-2{margin-left:16.66666667%}.col-sm-offset-1{margin-left:8.33333333%}.col-sm-offset-0{margin-left:0}}*/
@font-face {
    font-family: 'Droid Arabic Kufi';
    font-style: normal;
    font-weight: 400;
    src: url(//fonts.gstatic.com/ea/droidarabickufi/v6/DroidKufi-Regular.eot);
    src: url(//fonts.gstatic.com/ea/droidarabickufi/v6/DroidKufi-Regular.eot?#iefix) format('embedded-opentype'),
        url(//fonts.gstatic.com/ea/droidarabickufi/v6/DroidKufi-Regular.woff2) format('woff2'),
        url(//fonts.gstatic.com/ea/droidarabickufi/v6/DroidKufi-Regular.woff) format('woff'),
        url(//fonts.gstatic.com/ea/droidarabickufi/v6/DroidKufi-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Droid Arabic Kufi';
    font-style: normal;
    font-weight: 700;
    src: url(//fonts.gstatic.com/ea/droidarabickufi/v6/DroidKufi-Bold.eot);
    src: url(//fonts.gstatic.com/ea/droidarabickufi/v6/DroidKufi-Bold.eot?#iefix) format('embedded-opentype'),
        url(//fonts.gstatic.com/ea/droidarabickufi/v6/DroidKufi-Bold.woff2) format('woff2'),
        url(//fonts.gstatic.com/ea/droidarabickufi/v6/DroidKufi-Bold.woff) format('woff'),
        url(//fonts.gstatic.com/ea/droidarabickufi/v6/DroidKufi-Bold.ttf) format('truetype');
}

html,
body {
    font-family: 'Droid Arabic Kufi';
    height: 100%;
}

#imp-note {
    font-size: 18px;
}

#outer-wrapper {
    min-height: 100%;
    background-image: url('../img/bg/main-bg1.jpg');
    background-repeat: no-repeat;
    -moz-background-size: cover;
    -o-background-size: cover;
    -webkit-background-size: cover;
    background-size: cover;
    background-attachment: fixed;
    /*width: 1000px;*/
    /*margin-top: 200px;*/
    /*padding:0px;*/
    /*margin:0px;*/

}

#reg-form {
    /*width: 60%;*/
    padding: 2em;
    padding-top: 0;
    margin: 0px;
}

#feedback {
    width: 50%;
    margin: 3em auto;
}

#inner-wrapper {
    margin: 7em 0;
}

/*#submitBtn{*/
/*    background-image: none;*/
/*    background-color: #8ec63f;*/
/*    color: #fff !important;*/
/*    border: none;*/
/*    border-radius: 2px;*/
/*    box-shadow: 0 1px 2px 0 rgba(0,0,0,0.34);*/
/*    text-shadow: none;*/
/*    padding: 6px 18px;*/
/*    width: 50%;*/
/*    font-size: 1.5em;*/
/*    height: 50px;*/
/*}*/
/*#submitBtn:hover{*/
/*    background-color: #77ae29;*/
/*    color: #fff;*/
/*    transition: all 0.3s ease 0.1s;*/
/*}*/

#submitBtn {
    padding: .5em;
    width: 100%;
    margin-top: 1em;
}

#loginFormContainer {
    /*background-color: #b1833f;*/
    padding: 0 !important;
}

#login-form {
    margin-top: 3em;

}

.card {
    display: inline-block;
    position: relative;
    width: 100%;
    border-radius: 3px;
    color: rgba(0, 0, 0, 0.87);
    background: #fff;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.card .card-height-indicator {
    margin-top: 100%;
}

.card .card-content {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.card .card-image {
    height: 60%;
    position: relative;
    overflow: hidden;
}

.card .card-image img {
    width: 100%;
    height: 100%;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    pointer-events: none;
}

.card .card-image .card-image-headline {
    position: absolute;
    bottom: 16px;
    left: 18px;
    color: #fff;
    font-size: 2em;
}

.card .content {
    padding: 15px;
    overflow: hidden;
}

.card .card-body {
    height: 30%;
    padding: 18px;
}

.card .card-footer {
    height: 10%;
    padding: 18px;
    margin: 0 2em;
    border-top: 1px solid #eeeeee;
}

.card .header {
    box-shadow: 0 16px 38px -12px rgba(0, 0, 0, 0.56), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
    margin: 15px;
    border-radius: 3px;
    padding: 15px 0;
    background-color: #FFFFFF;
}

.card .header-primary {
    background: linear-gradient(60deg, #ab47bc, #7b1fa2);
}

.card .header-info {
    background: linear-gradient(60deg, #29b6f6, #0288d1);
}

.card .header-success {
    background: linear-gradient(60deg, #66bb6a, #388e3c);
}

.card .header-warning {
    background: linear-gradient(60deg, #fdd835, #fbc02d);
}

.card .header-danger {
    background: linear-gradient(60deg, #ef5350, #d32f2f);
}

.card [class*="header-"] {
    color: #FFFFFF;
}

.card-raised {
    box-shadow: 0 16px 38px -12px rgba(0, 0, 0, 0.56), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
}

.zsap-card {
    max-width: 350px;
    background: linear-gradient(60deg, #b1833f, #9f734d);
    margin: 0 auto;
    width: 100%;
}

.card .zsap-header-primary {
    /*background: linear-gradient(60deg, #ab47bc, #b1833f);*/
    margin-top: -3em;
}

.zsap-input-group-addon {
    border-color: #7d4b20;
    color: #7d4b20;
}

.input-group-addon:first-child {
    border-right: 1px solid #7d4b20;
}

.zsap-input {
    border-color: #7d4b20;
}

.zsap-btn-login {
    transition: background-color 0.5s ease;
    outline: 0;
    color: #7d4b20;
    border: none;
    background-color: #FFFFFF;
    box-shadow: 0 2px 2px 0 rgba(156, 39, 176, 0.14), 0 3px 1px -2px rgba(156, 39, 176, 0.2), 0 1px 5px 0 rgba(156, 39, 176, 0.12);
}

.zsap-btn-login:hover {
    color: #FFFFFF;
    background-color: #9f734d;
    box-shadow: 0 14px 26px -12px rgba(156, 39, 176, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(156, 39, 176, 0.2);
}

.zsap-footer-a {
    color: #FFFFFF;
    text-decoration: none;
}

.zsap-footer-a::active,
:visited {
    color: #9c27b0;
}

.zsap-footer-a:hover {
    color: #03a9f4;
    text-decoration: none;
}

.sk-folding-cube {
    margin: 0 auto;
    top: 47%;
    width: 40px;
    height: 40px;
    position: relative;
    -webkit-transform: rotateZ(45deg);
    transform: rotateZ(45deg);
}

.sk-folding-cube .sk-cube {
    float: left;
    width: 50%;
    height: 50%;
    position: relative;
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
}

.sk-folding-cube .sk-cube:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #555;
    -webkit-animation: sk-foldCubeAngle 2.4s infinite linear both;
    animation: sk-foldCubeAngle 2.4s infinite linear both;
    -webkit-transform-origin: 100% 100%;
    -ms-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
}

.sk-folding-cube .sk-cube2 {
    -webkit-transform: scale(1.1) rotateZ(90deg);
    transform: scale(1.1) rotateZ(90deg);
}

.sk-folding-cube .sk-cube3 {
    -webkit-transform: scale(1.1) rotateZ(180deg);
    transform: scale(1.1) rotateZ(180deg);
}

.sk-folding-cube .sk-cube4 {
    -webkit-transform: scale(1.1) rotateZ(270deg);
    transform: scale(1.1) rotateZ(270deg);
}

.sk-folding-cube .sk-cube2:before {
    -webkit-animation-delay: 0.3s;
    animation-delay: 0.3s;
}

#submit-loader {
    background-color: #FFFFFF;
    display: none;
    opacity: .7;
}

.loader-text-indicator {
    position: relative;
    top: 63%;
    font-weight: bold;
    color: #555;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
}

.sk-folding-cube .sk-cube3:before {
    -webkit-animation-delay: 0.6s;
    animation-delay: 0.6s;
}

.sk-folding-cube .sk-cube4:before {
    -webkit-animation-delay: 0.9s;
    animation-delay: 0.9s;
}

@-webkit-keyframes sk-foldCubeAngle {

    0%,
    10% {
        -webkit-transform: perspective(140px) rotateX(-180deg);
        transform: perspective(140px) rotateX(-180deg);
        opacity: 0;
    }

    25%,
    75% {
        -webkit-transform: perspective(140px) rotateX(0deg);
        transform: perspective(140px) rotateX(0deg);
        opacity: 1;
    }

    90%,
    100% {
        -webkit-transform: perspective(140px) rotateY(180deg);
        transform: perspective(140px) rotateY(180deg);
        opacity: 0;
    }
}

@keyframes sk-foldCubeAngle {

    0%,
    10% {
        -webkit-transform: perspective(140px) rotateX(-180deg);
        transform: perspective(140px) rotateX(-180deg);
        opacity: 0;
    }

    25%,
    75% {
        -webkit-transform: perspective(140px) rotateX(0deg);
        transform: perspective(140px) rotateX(0deg);
        opacity: 1;
    }

    90%,
    100% {
        -webkit-transform: perspective(140px) rotateY(180deg);
        transform: perspective(140px) rotateY(180deg);
        opacity: 0;
    }
}

.errors-console {
    /*width: 110%;*/
    background-color: #0D2C3B;
    /*margin-left: -5%;*/
    padding: .5em;
    -webkit-font-smoothing: antialiased
}

.login-errors-item {
    text-align: justify;
    margin: 1em;
}

.login-errors-item-indicator,
.login-errors-item-message {
    display: inline;
}

.login-errors-item-indicator {
    font-weight: bold;
    font-size: 1em;
}

#login-form-logo {
    width: 70% !important;
}

.error {
    color: #E0386A;
}

.warning {
    color: #FC8F4F;
}

#username {
    text-transform: lowercase;
}

.card-additional-links {
    width: 80%;
    max-width: 320px;
    display: flex;
    justify-content: space-between;
    margin: auto;
    background: #fff;
    padding: .5rem .5rem;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    border-radius: 0 0 3px 3px;
}

.card-additional-links a {
    text-decoration: none;
    color: #999999;
    font-size: 12px;
}
